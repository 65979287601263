import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "../../assets/css/techniczny.css"

const NarzedziaReczne = () => {
  return (
    <div id="narzedzia_reczne">
      <div className="technicznyElement">
        <StaticImage
          src="../../assets/images/techniczny/narzedzia_reczne.png"
          alt="narzedzia_reczne"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder">
          <h3>NARZĘDZIE RĘCZNE</h3>
          <p>
            Nasza oferta obejmuje narzędzia do użytku profesjonalnego oraz na
            potrzeby prac przydomowych. W naszych sklepach można znaleźć sprzęt
            takich marek, jak: MILWAUKEE, KNIPEX, TAJIMA, HALDER, BACHCO,
            HONITON, WERA, RICHMANN. Nasi doradcy pomogą w wyborze najlepszych
            narzędzi.
          </p>
        </div>
      </div>
    </div>
  )
}

export default NarzedziaReczne
