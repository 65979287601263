import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
//My Components
import Top from "../components/Top"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"
import TechnicznyMenu from "../components/techniczny/TechnicznyMenu"
import Elektronarzedzia from "../components/techniczny/Elektronarzedzia"
import Spawalnictwo from "../components/techniczny/Spawalnictwo"
import NarzedziaReczne from "../components/techniczny/NarzedziaReczne"
import Pneumatyka from "../components/techniczny/Pneumatyka"
import PistoletyKlejowe from "../components/techniczny/PistoletyKlejowe"
import NaszeMarki from "../components/techniczny/NaszeMarki"
import Seo from "../components/Seo"

// Query for Top image and Contact Form background image
export const query = graphql`
  {
    form: file(
      name: { eq: "techniczny" }
      sourceInstanceName: { eq: "formBackgrounds" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    top: file(
      name: { eq: "techniczny" }
      sourceInstanceName: { eq: "topImages" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`

const SklepTechniczny = () => {
  const data = useStaticQuery(query)
  const imgForm = getImage(data.form)
  const imgTop = getImage(data.top)
  return (
    <div id="sklep_techniczny" className="page technicznyPage">
      <Seo title="Sklep techniczny" />
      <Top image={imgTop} />
      <TechnicznyMenu />
      <Elektronarzedzia />
      <Spawalnictwo />
      <NarzedziaReczne />
      <Pneumatyka />
      <PistoletyKlejowe />
      <NaszeMarki />
      <ContactForm backgroundImage={imgForm} nrTel="781 999 804/ 733 670 370" />
      <Footer nrTel="781 999 804/ 733 670 370" email="ekasklep@gmail.com" />
    </div>
  )
}

export default SklepTechniczny
