import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "../../assets/css/techniczny.css"

const Spawalnictwo = () => {
  return (
    <div id="spawalnictwo">
      <div className="technicznyElement">
        <StaticImage
          src="../../assets/images/techniczny/spawalnictwo.png"
          alt="spawalnictwo"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <h3>SPAWALNICTWO</h3>
          <p>
            Spawalnictwo to proces trwałego łączenia metali i ich stopów.
            Oferujemy kompleksowe, profesjonalne wyposażenie stanowiska
            spawalniczego. Proponujemy produkty marki MAGNUM – uznanego na rynku
            producenta spawarek, przecinarek plazmowych czy prostowników. W
            naszych placówkach można także nabyć materiały i akcesoria
            spawalnicze.
          </p>
          <p>
            W ofercie posiadamy spawarki MIG/MAG, MMA, TIG oraz cały osprzęt
            niezbędny do funkcjonowania urządzenia: uchwyty, druty, elektrody
            czy przyłbice. W naszych sklepach można zaopatrzyć się również w
            zgrzewarki i przecinarki plazmowe, a także prostowniki. Nasi
            sprzedawcy chętnie doradzą w doborze optymalnych rozwiązań.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Spawalnictwo
