import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import "../../assets/css/techniczny.css"

const NaszeMarki = () => {
  return (
    <div id="nasze_marki">
      <h3>Nasze Marki</h3>
      <StaticImage
        src="../../assets/images/techniczny/nasze_marki.png"
        alt="nasze_marki"
        layout="fullWidth"
      />
    </div>
  )
}

export default NaszeMarki
