import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "../../assets/css/techniczny.css"

const Pneumatyka = () => {
  return (
    <div id="pneumatyka">
      <div className="technicznyElement">
        <StaticImage
          src="../../assets/images/techniczny/pneumatyka.png"
          alt="pneumatyka"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder">
          <h3>PNEUMATYKA</h3>
          <p>
            Oferujemy kompresory olejowe i bezolejowe, przy wyborze których
            pomoże nasz zespół, dopasowując produkt do oczekiwań klienta.
            Proponujemy także szeroką gamę węży, zaworów, elektrozaworów,
            armatury i szybkozłączy. Współpracujemy z markami: MAGNUM, WELDMAN,
            SUMAKE.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Pneumatyka
