import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import SideMenu from "../SideMenu"

import "../../assets/css/techniczny.css"

const TechnicznyMenu = () => {
  return (
    <>
      {" "}
      <div className="menu">
        <AnchorLink
          to="/sklep_techniczny#elektronarzedzia"
          className="menuElement sixElementMenu"
        >
          <StaticImage
            src="../../assets/images/techniczny/menu/elektronarzedzia.png"
            alt="elektronarzedzia"
            placeholder="tracedSVG"
          />
          <h3>elektronarzedzia</h3>
        </AnchorLink>
        <AnchorLink
          to="/sklep_techniczny#spawalnictwo"
          className="menuElement sixElementMenu"
        >
          <StaticImage
            src="../../assets/images/techniczny/menu/spawalnictwo.png"
            alt="spawalnictwo"
            placeholder="tracedSVG"
          />
          <h3>spawalnictwo</h3>
        </AnchorLink>
        <AnchorLink
          to="/sklep_techniczny#narzedzia_reczne"
          className="menuElement sixElementMenu"
        >
          <StaticImage
            src="../../assets/images/techniczny/menu/narzedzia_reczne.png"
            alt="narzedzia_reczne"
            placeholder="tracedSVG"
          />
          <h3>narzędzia ręczne</h3>
        </AnchorLink>
        <AnchorLink
          to="/sklep_techniczny#pneumatyka"
          className="menuElement sixElementMenu"
        >
          <StaticImage
            src="../../assets/images/techniczny/menu/pneumatyka.png"
            alt="pneumatyka"
            placeholder="tracedSVG"
          />
          <h3>pneumatyka</h3>
        </AnchorLink>
        <AnchorLink
          to="/sklep_techniczny#pistolety_klejowe"
          className="menuElement sixElementMenu"
        >
          <StaticImage
            src="../../assets/images/techniczny/menu/pistolety_klejowe.png"
            alt="pistolety_klejowe"
            placeholder="tracedSVG"
          />
          <h3>pistolety klejowe</h3>
        </AnchorLink>
        <AnchorLink
          to="/sklep_techniczny#nasze_marki"
          className="menuElement sixElementMenu"
        >
          <StaticImage
            src="../../assets/images/techniczny/menu/nasze_marki.png"
            alt="nasze_marki"
            placeholder="tracedSVG"
          />
          <h3>nasze marki</h3>
        </AnchorLink>
      </div>
      <SideMenu base="/sklep_techniczny#sklep_techniczny">
        <div className="sideMenuElement">
          <h3>
            elektro-
            <br />
            narzedzia
          </h3>
          <AnchorLink to="/sklep_techniczny#elektronarzedzia">
            <StaticImage
              src="../../assets/images/techniczny/menu/elektronarzedzia.png"
              alt="elektronarzedzia"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>spawalnictwo</h3>
          <AnchorLink to="/sklep_techniczny#spawalnictwo">
            <StaticImage
              src="../../assets/images/techniczny/menu/spawalnictwo.png"
              alt="spawalnictwo"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>narzędzia ręczne</h3>
          <AnchorLink to="/sklep_techniczny#narzedzia_reczne">
            <StaticImage
              src="../../assets/images/techniczny/menu/narzedzia_reczne.png"
              alt="narzedzia_reczne"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>pneumatyka</h3>
          <AnchorLink to="/sklep_techniczny#pneumatyka">
            <StaticImage
              src="../../assets/images/techniczny/menu/pneumatyka.png"
              alt="pneumatyka"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>pistolety klejowe</h3>
          <AnchorLink to="/sklep_techniczny#pistolety_klejowe">
            <StaticImage
              src="../../assets/images/techniczny/menu/pistolety_klejowe.png"
              alt="pistolety_klejowe"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>nasze marki</h3>
          <AnchorLink to="/sklep_techniczny#nasze_marki">
            <StaticImage
              src="../../assets/images/techniczny/menu/nasze_marki.png"
              alt="nasze_marki"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
      </SideMenu>
    </>
  )
}

export default TechnicznyMenu
