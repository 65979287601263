import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "../../assets/css/techniczny.css"

const PistoletyKlejowe = () => {
  return (
    <div id="pistolety_klejowe">
      <div className="technicznyElement">
        <StaticImage
          src="../../assets/images/techniczny/pistolety.png"
          alt="pistolety_klejowe"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder">
          <h3>PISTOLETY KLEJOWE</h3>
          <p>
            W ofercie posiadamy pistolety klejowe różnych marek. W naszych
            placówkach zakupić można m.in. urządzenia i kleje marki BÜHNEN,
            charakteryzujące się wysoką jakością i znajdujące zastosowanie w
            wielu branżach – np. w obszarach produkcyjnych do klejenia, montażu,
            zabezpieczania materiałów, jako uszczelnienie lub masa odlewnicza.
            Zachęcamy do zapoznania się z naszymi propozycjami.
          </p>
        </div>
      </div>
    </div>
  )
}

export default PistoletyKlejowe
