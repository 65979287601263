import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import "../../assets/css/techniczny.css"

const Elektronarzedzia = () => {
  return (
    <div id="elektronarzedzia">
      <div className="technicznyElement">
        <StaticImage
          src="../../assets/images/techniczny/elektronarzedzia.png"
          alt="elektronarzedzia"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <h3>ELEKTRONARZĘDZIA</h3>
          <p>
            Ponad 30 lat działamy na rynku narzędziowym. Doświadczenie, które
            zdobyliśmy w tym czasie, pozwala nam zaproponować sprzęt precyzyjnie
            dopasowany do potrzeb każdego klienta. Nasza oferta obejmuje zarówno
            sprzęt dla profesjonalistów, jak i majsterkowiczów. W naszych
            sklepach posiadamy szeroki asortyment produktów marek: MILWAUKEE,
            DEWALT, MAKITA, BOSH, X-TOOLS, DEDRA.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Elektronarzedzia
